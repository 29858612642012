import React from "react";
import Lottie from "lottie-react";
import "../account/Account.css";
import LoadingComponent from "./LoadingComponent";

class ManagePlanCell extends React.Component {  
    render() {
        const { accountType, planRenewDate } = this.props;

        const green = "#0f4614";
        const greenBackground = "#65b26c";
        const blue = "#1961A8";
        const blueBackground = "#D1DFEE20";
        const gold = "#F0B52C";
        const goldBackground = "#FCF0D520";

        return (
            <div>
                {(accountType === "")? (
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0'}}>Current Plan</h4>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                            <LoadingComponent style={{height: '40px'}} />
                        </div>
                    </div>
                ) : (
                    <div>
                    {(accountType === "basic")? (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0'}}>Current Plan</h4>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                                <img src={require(`../assets/images/basicCircle.png`)} style={{width: '100px', height: '100px'}} />
                                <div style={{display: 'grid'}}>
                                    <a className="inline-btn inline-blue-btn-dash" href="/pricing" target="blank" style={{margin: "auto 16px auto auto"}}>Upgrade</a>
                                    {/* <h4 className="score-tex gold-text" style={{textAlign: 'end', color: `${accountType == "plus" ? gold : green}`, background: `${accountType == "plus" ? goldBackground : greenBackground}`, fontWeight: `800`}}>{`${accountType == "plus" ? 'Pro Talk - Plus' : 'Pro Talk - Premium'}`}</h4> */}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h4 style={{fontWeight: '500', color: 'white', margin: '24px 0 0 0'}}>Current Plan</h4>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
                                <img src={require(`../assets/images/${accountType === "plus" ? "plusCircle" : "premiumCircle"}.png`)} style={{width: '100px', height: '100px'}} />
                                <div style={{display: 'grid'}}>
                                    <p className="score-text" style={{margin: "auto 16px auto auto"}}>Renews on {planRenewDate}</p>
                                    <a className="inline-btn inline-blue-btn-dash" href="https://billing.stripe.com/p/login/aEU5mc7T9aIcdu8dQQ" target="blank" style={{margin: "auto 16px auto auto"}}>Manage Plan</a>
                                    {/* <h4 className="score-tex gold-text" style={{textAlign: 'end', color: `${accountType == "plus" ? gold : green}`, background: `${accountType == "plus" ? goldBackground : greenBackground}`, fontWeight: `800`}}>{`${accountType == "plus" ? 'Pro Talk - Plus' : 'Pro Talk - Premium'}`}</h4> */}
                                </div>
                            </div>
                        </div>
                    )}
                    </div>
                )}
            </div>
        );
    }
  }
  
export default ManagePlanCell;