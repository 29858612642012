import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import "./Upgrade.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Player } from '@lottiefiles/react-lottie-player';

function Upgrade() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [hasGold, setGold] = useState(false);
  const navigate = useNavigate();
  const fetchGoldStatus = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setGold(data.hasGold);
    } catch (err) {
      console.error(err);
      console.log("An error occured while fetching user data");
    }
  };

  const red = "#C65447";
  const redBackground = "#F4DDDA";
  const green = "#509E6F";
  const greenBackground = "#DCECE2";
  const blue = "#1961A8";
  const blueBackground = "#D1DFEE";
  const yellow = "#FFC337";
  const orange = "#ED6742";
  const gold = "#F0B52C";
  const goldBackground = "#FCF0D5";

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  function showComingSoon() {
    alert("Single Player Game Coming Soon")
  }; 
  useEffect(() => {
    fetchGoldStatus();
  }, [user, loading]);
  return (
    <div className="page-container">
        <h1 className="upgrade-title" style={{margin: '24px auto 0px auto'}}><span style={{color: blue}}>P</span><span style={{color: red}}>R</span><span style={{color: yellow}}>O</span> <span style={{color: green}}>T</span><span style={{color: orange}}>A</span><span style={{color: blue}}>L</span><span style={{color: red}}>K</span></h1>
        <div style={{margin: '0 auto'}}>
            <p style={{paddingLeft: '64px', paddingRight: '64px', paddingTop: '14px', paddingBottom: '14px', backgroundColor: goldBackground, borderRadius: '12px', color: gold, fontWeight: '700', textAlign: 'center', width: 'fit-content', margin: '0 auto'}}>GOLD</p>
            <p style={{color: 'gray'}}>Upgrade to Pro Talk Gold to access all content! You will gain unlimited access to Pro Talk cards, and the Pro Talk book. Create unlimited survey groups and take the survey with no restrictions. You will also be able to see a history of all surveys taken in the past.</p>
            <p></p>
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table pricing-table-id="prctbl_1OZmthDmRtME6qTqXViClCyD"
            publishable-key="pk_live_51OZmNwDmRtME6qTqMetVAuPuyiJh30Ms1wKJAsfbcdZ0LKeaYpNNj1QZEOyFa48TYvZBJu65VDhRge15nlVTXa9g00JsdvwLfT">
            </stripe-pricing-table>
        </div> 
    </div>
  );
}
export default Upgrade;