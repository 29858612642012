import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "../App.css";
import { auth, db, logout } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { Player } from '@lottiefiles/react-lottie-player';

function Tutorial() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const fetchUserName = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setName(data.name);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };
  function showComingSoon() {
    alert("Single Player Game Coming Soon")
  }; 
  useEffect(() => {
    
  }, [user, loading]);
  return (
    <div className="dashboard-container">
      <a className="final-submit-btn blue-btn" style={{marginTop: '48px', marginBottom: '24px'}} href="https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayProTalk.mp4?alt=media&token=776fe4d3-8989-4412-aa98-b3aaf19e6433" target="blank">Watch the Video</a>
      <a className="final-submit-btn green-btn" href="https://firebasestorage.googleapis.com/v0/b/pro-talk-game.appspot.com/o/HowToPlayPDF.pdf?alt=media&token=d4aba260-22e1-4acf-a5c4-d9fad7766c22" target="blank" >View the PDF Instructions</a>
    </div>
  );
}
export default Tutorial;